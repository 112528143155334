@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

$window-width-design: 1600px;
$window-width-default: 1080px;
$app-padding-x: 46px;

$main-width: 400px;
$main-height: 1000px;

// [Fonts]
$font-name-default: 'Cambria', sans-serif;
$font-name-header: 'Sacra', serif;
$font-size-default: 35px;
$font-size-large: 56px;
$font-size-biggest: 76px;
$font-size-medium: 46px;
$font-size-medium-small: 30px;
$font-size-small: 28px;
$font-size-tiny: 24px;
$font-size-tiny-small: 20px;
$font-size-smallest: 18px;
$font-size-micro: 16px;

// [Layout step]
$grid-offset-step: 4px;

// [Animations]
$animation-step: 160ms;

.pointer {
  user-select: none;
  cursor: url('/assets/images/cursor/cursor-50-active.avif') 10 0, pointer;
}

.no-action {
  user-select: none;
  pointer-events: none;
}

.hidden {
  display: none !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.animation-hidden {
  visibility: hidden;
}

.animation-hidden-transition {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.animation-show-transition {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.width-100-percent {
  width: 100%;
}

@keyframes button-process-animation {
  from {
    text-shadow: none;
  }

  to {
    text-shadow: 0 0 16px #dfa94e;
  }
}

@keyframes opacity-process-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.opacity-process-animation {
  animation-duration: 1s;
  animation-name: opacity-process-animation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.blink-process-animation {
  animation-duration: 0.7s;
  animation-name: opacity-process-animation;
  animation-iteration-count: 7;
  animation-direction: alternate;
}

.button-process-animation {
  animation-duration: 1s;
  animation-name: button-process-animation;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.page-loader {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.info-icon {
  width: 61px;
  height: 61px;
}

// [Gaps]
@for $i from 1 through 100 {
  .gap-#{$i * 2} {
    gap: #{$i * 2}px;
  }
}

// [Width]
@for $i from 0 through 500 {
  .width-#{$i} {
    width: #{$i}px;
  }
}

// [Max Width]
@for $i from 1 through 1000 {
  .max-width-#{$i} {
    max-width: #{$i}px;
  }
}

// [Margin]
@for $i from 1 through 120 {
  .margin-#{$i} {
    margin: #{$i}px;
  }

  .margin-top-#{$i} {
    margin-top: #{$i}px;
  }

  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px;
  }

  .margin-left-#{$i} {
    margin-left: #{$i}px;
  }

  .margin-right-#{$i} {
    margin-right: #{$i}px;
  }
}

// [Line height]
@for $i from 100 through 200 {
  .line-height-#{$i} {
    line-height: #{$i}#{"%"};
  }
}
